import React, {useContext} from "react";
import Paper from "@mui/material/Paper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AppContext} from "../context/AppProvider";
import Typography from "@mui/material/Typography";
import {faLandmark} from "@fortawesome/free-solid-svg-icons/faLandmark";

const CommuneWidget = ({
                         sx,
                         commune
                       }) => {
  const {
    isMobile,
  } = useContext(AppContext)

  function capitalizeWords(str) {
    const lowercaseWords = ["le", "la", "les", "de", "des", "du", "au", "aux", "en", "sur", "et", "ou"];
    return str
      .toLowerCase()
      .split(" ")
      .map((word, index) =>
        // Capitalize the first word and all words not in lowercaseWords list
        (index === 0 || !lowercaseWords.includes(word))
          ? word.charAt(0).toUpperCase() + word.slice(1)
          : word
      )
      .join(" ");
  }

  return <>
    <Paper
      sx={{
        ...sx,
        display: "flex",
        flexDirection: "column",
        justifySelf: "center",
        padding: "20px",
        borderRadius: "20px",
        alignItems: "flex-start",
        width: isMobile ? "200px" : "300px",
        fontSize: isMobile ? "80%" : "100%",
      }}
    >
      <FontAwesomeIcon style={{
        position: "absolute",
        top: "23px",
        right: "23px",
      }} icon={faLandmark}/>

      <Typography sx={{
        fontFamily: "\"Lato\", sans-serif",
        fontSize: "120%",
        paddingRight: "20px"
      }} variant="h1">
        {capitalizeWords(commune.nom)}
      </Typography>
      <Typography sx={{
        fontFamily: "\"Lato\", sans-serif",
        fontSize: "85%"
      }} variant="h2">
        {commune.code_postal}
      </Typography>

      <Typography sx={{
        paddingTop: isMobile ? "10px" : "20px",
        fontFamily: "\"Lato\", sans-serif",
        fontSize: "80%"
      }} variant="h3">
        {commune.nom_region}
      </Typography>
      <Typography sx={{
        fontFamily: "\"Lato\", sans-serif",
        fontSize: "75%"
      }} variant="h4">
        {commune.nom_departement}
      </Typography>
    </Paper>

  </>
    ;
};

export default CommuneWidget;
