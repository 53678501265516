import React, {useContext, useRef} from "react";
import Paper from "@mui/material/Paper";
import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import {MapContext} from "./context/MapProvider";
import ParcelleCard from "./common/ParcelleCard";
import {AppContext} from "./context/AppProvider";
import {SearchContext} from "./context/SearchProvider";
import CommuneWidget from "./ExploreResultInfo/CommuneWidget";
import ZonageWidget from "./ExploreResultInfo/ZonageWidget";
import Button from "@mui/material/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleUp} from "@fortawesome/free-solid-svg-icons/faAngleUp";
import {faAngleDown} from "@fortawesome/free-solid-svg-icons/faAngleDown";
import PopulationWidget from "./ExploreResultInfo/PopulationWidget";

const ExploreResultInfo = ({
                             style
                           }) => {
  const theme = useTheme();

  const itemRef = useRef(null);
  const navigationRef = useRef(null);

  const {
    isMobile,
    page,
    bottomHeight,
    isLightTheme,
    showCommuneWidgets,
    setShowCommuneWidgets
  } = useContext(AppContext)

  const {
    commune,
  } = useContext(SearchContext)

  const {
    features,
    selectedFeature,
  } = useContext(MapContext);

  const properties = selectedFeature ? selectedFeature.getProperties() : [];

  let hasZonageData = (commune.surface_a_ha
    + commune.surface_ah_ha
    + commune.surface_auc_ha
    + commune.surface_aus_ha
    + commune.surface_n_ha
    + commune.surface_nd_ha
    + commune.surface_nh_ha
    + commune.surface_u_ha) > 0;
  hasZonageData = false;

  const hasPopulationData = false;

  return <>
    <Box
      sx={{
        position: "absolute",
        top: "calc(10px + env(safe-area-inset-top))",
        right: "10px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        alignItems: "end"
      }}
    >
      <CommuneWidget
        sx={{
          marginLeft: isMobile ? "-50px" : "-150px",
        }}
        commune={commune}
      />

      {showCommuneWidgets && <>
        {hasZonageData && <>
          <ZonageWidget
            surfaceCommune={commune.surface}
            surfaceA={commune.surface_a_ha}
            surfaceAH={commune.surface_ah_ha}
            surfaceAUC={commune.surface_auc_ha}
            surfaceAUS={commune.surface_aus_ha}
            surfaceN={commune.surface_n_ha}
            surfaceND={commune.surface_nd_ha}
            surfaceNH={commune.surface_nh_ha}
            surfaceU={commune.surface_u_ha}
          />
        </>}

        {hasPopulationData && <>
          <PopulationWidget
            population={commune.population}
            niveauDensite={commune.niveau_densite}
          />
        </>}

      </>}

      {(hasZonageData || hasPopulationData) && <Button
        onClick={() => {
          setShowCommuneWidgets(!showCommuneWidgets);
        }}
        variant="contained-clear"
        sx={{
          height: "20px",
          borderRadius: "20px",
          minWidth: "40px",
        }}
      >
        {showCommuneWidgets && <FontAwesomeIcon icon={faAngleUp}/>}
        {!showCommuneWidgets && <FontAwesomeIcon icon={faAngleDown}/>}
      </Button>}
    </Box>

    {selectedFeature !== null && (
      <Box
        variant="outlined"
        tabIndex={-1}
        elevation={0}
        sx={{
          position: "absolute",
          bottom: bottomHeight + 10 + "px",
          maxWidth: "64%",
          left: "50%",
          transform: "translate(-50%, 0%)",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyItems: "center",
          gap: "10px",
          [theme.breakpoints.down("sm")]: {
            fontSize: "80%",
            width: "100%",
            maxWidth: "100%",
            left: "0%",
            transform: "translate(0%, 0%)",
            gap: "10px",
            bottom: bottomHeight + "px",
          },
          ...style,
        }}
      >
        <Paper
          ref={navigationRef}
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyItems: "center",
            overflowX: "scroll",
            scrollbarWidth: "none",
            borderRadius: "35px",
            padding: (selectedFeature === null ? "10px" : "15px 0px"),
            "&::-webkit-scrollbar": {
              display: "none"
            },
            [theme.breakpoints.down("sm")]: {
              borderRadius: "0px",
            }
          }}>
          <ParcelleCard
            sx={{
              fontSize: isMobile ? "120%" : "100%",
            }}
            hideDelete={true}
            selected={true}
            reference_cadastrale={properties["reference_cadastrale"]}
            adresse={properties["adresse"]}
            longitude={properties["longitude"]}
            latitude={properties["latitude"]}
            commune={properties["commune"]}
            surface={properties["surface"]}
            ref={(el) => (itemRef.current = el)}
          />
        </Paper>
      </Box>
    )}
  </>;
};

export default ExploreResultInfo;
