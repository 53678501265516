import React, {useContext, useEffect, useRef, useState} from "react";
import Paper from "@mui/material/Paper";
import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {MapContext} from "./context/MapProvider";
import ParcelleCard from "./common/ParcelleCard";
import ParcelleRegroupeeCard from "./common/ParcelleRegroupeeCard";
import {AppContext} from "./context/AppProvider";
import {SearchContext} from "./context/SearchProvider";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons/faChevronRight";
import Typography from "@mui/material/Typography";

const SearchResultInfo = ({
                            style
                          }) => {
  const theme = useTheme();

  const itemRefs = useRef([]);
  const navigationRef = useRef(null);

  const {
    isMobile,
    isLightTheme,
    page,
    bottomHeight
  } = useContext(AppContext)

  const [isOverflowing, setIsOverflowing] = useState(isMobile);

  const {
    commune,
  } = useContext(SearchContext)

  const {
    features,
    setFeatures,
    selectedFeature,
    setSelectedFeature,
    showCadastreLayer,
    toggleCadastreLayer
  } = useContext(MapContext);

  let countText = features.length + (features.length > 1 ? " résultats trouvés" : " résultat trouvé");

  const checkOverflow = () => {
    const div = navigationRef.current;
    if (div && !isMobile) {
      const overflow = div.scrollHeight > div.clientHeight || div.scrollWidth > div.clientWidth;
      setIsOverflowing(overflow);
    }
  };

  useEffect(() => {
    const index = features.indexOf(selectedFeature);
    if (itemRefs.current[index]) {
      itemRefs.current[index].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [selectedFeature]);

  useEffect(() => {
    checkOverflow();
  }, [features]);

  useEffect(() => {
    window.addEventListener("resize", checkOverflow); // Vérifier aussi en cas de redimensionnement de la fenêtre
    return () => {
      window.removeEventListener("resize", checkOverflow); // Nettoyage lors du démontage du composant
    };
  }, []);


  return <>
    <Paper
      sx={{
        position: "absolute",
        display: "flex",
        flexDirection: isMobile ? "row" : "column",
        top: "calc(10px + env(safe-area-inset-top))",
        right: "10px",
        justifySelf: "center",
        padding: isMobile ? "10px" : "15px",
        borderRadius: "20px",
        fontSize: "80%",
        gap: "10px",
        alignItems: "center"
      }}
    >
      <Typography sx={{
        fontFamily: "\"Lato\", sans-serif",
        fontSize: "115%"
      }} variant="h1">
        {countText}
      </Typography>
    </Paper>

    <Box
      variant="outlined"
      tabIndex={-1}
      elevation={0}
      sx={{
        position: "absolute",
        bottom: bottomHeight + 10 + "px",
        maxWidth: "64%",
        left: "50%",
        transform: "translate(-50%, 0%)",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyItems: "center",
        gap: "10px",
        [theme.breakpoints.down("sm")]: {
          fontSize: "80%",
          width: "100%",
          maxWidth: "100%",
          left: "0%",
          transform: "translate(0%, 0%)",
          gap: "10px",
          bottom: bottomHeight + "px",
        },
        ...style,
      }}
    >
      <Button
        disabled={features.indexOf(selectedFeature) === 0}
        variant="contained-clear"
        onClick={() => {
          const featureIndex = features.indexOf(selectedFeature);
          setSelectedFeature(features[Math.max(featureIndex - 1, 0)]);
        }}
        sx={{
          display: (isOverflowing && features.length > 1) ? "block" : "none",
          padding: "10px",
          borderRadius: "20px",
          [theme.breakpoints.down("sm")]: {
            position: "absolute",
            left: "5px",
            bottom: bottomHeight + 90 + "px",
            height: "64px",
            borderRadius: "100px",
            opacity: "70%",
            backdropFilter: "blur(50px) " + (isLightTheme ? "brightness(200%)" : "brightness(100%)"),
          }
        }}
      >
        <FontAwesomeIcon icon={faChevronLeft}/>
      </Button>

      <Paper
        ref={navigationRef}
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyItems: "center",
          overflowX: "scroll",
          scrollbarWidth: "none",
          borderRadius: "35px",
          padding: (features.length === 0 ? "10px" : "15px 0px"),
          "&::-webkit-scrollbar": {
            display: "none"
          },
          [theme.breakpoints.down("sm")]: {
            borderRadius: "0px",
          }
        }}>
        {features.length === 0 && <Box sx={{
          textAlign: "center",
          width: "100%",
          padding: "10px"
        }}>Aucun résultat pour votre recherche</Box>}

        {features.map((feature, index) => {
            const properties = feature.getProperties();
            const isRegroupee = properties["is_regroupee"];
            const parcelles = properties["parcelles"] || [];
            const isRegroupeeButUneParcelle = isRegroupee && parcelles.length === 1;
            const firstParcelleProperties = isRegroupee && properties["parcelles"][0].getProperties();
            const selected = selectedFeature && (properties["id_parcelle"] === selectedFeature.getProperties().id_parcelle);

            if (isRegroupee && !isRegroupeeButUneParcelle) {
              return <ParcelleRegroupeeCard
                onClick={() => {
                  setSelectedFeature(feature)
                }}
                sx={{
                  fontSize: isMobile ? "120%" : "100%",
                }}
                onDelete={() => {
                  const featuresFiltred = features.filter(elementFeature => properties["id_parcelle"] !== elementFeature.getProperties().id_parcelle);
                  setFeatures(featuresFiltred);
                  setSelectedFeature(null);
                }}
                selected={selected}
                key={index}
                reference_cadastrale={properties["reference_cadastrale"]}
                adresse={properties["adresse"]}
                longitude={properties["longitude"]}
                latitude={properties["latitude"]}
                commune={properties["commune"]}
                surface={properties["surface"]}
                ref={(el) => (itemRefs.current[index] = el)}
              >
                {parcelles.map((subFeature, subIndex) => {
                  const properties = subFeature.getProperties();
                  return <ParcelleCard
                    sx={{
                      fontSize: "80%",
                    }}
                    onClick={() => {
                      setSelectedFeature(subFeature)
                    }}
                    key={subIndex}
                    selected={selected}
                    isInRegroupement={true}
                    reference_cadastrale={properties["reference_cadastrale"]}
                    adresse={properties["adresse"]}
                    commune={properties["commune"]}
                    surface={properties["surface"]}
                  />
                })}
              </ParcelleRegroupeeCard>
            } else {
              return <ParcelleCard
                sx={{
                  fontSize: isMobile ? "120%" : "100%",
                }}
                onClick={() => {
                  setSelectedFeature(feature)
                }}
                onDelete={() => {
                  const featuresFiltred = features.filter(elementFeature => properties["id_parcelle"] !== elementFeature.getProperties().id_parcelle);
                  setFeatures(featuresFiltred);
                  setSelectedFeature(null);
                }}
                selected={selected}
                key={index}
                reference_cadastrale={(isRegroupeeButUneParcelle ? firstParcelleProperties : properties)["reference_cadastrale"]}
                adresse={(isRegroupeeButUneParcelle ? firstParcelleProperties : properties)["adresse"]}
                longitude={properties["longitude"]}
                latitude={properties["latitude"]}
                commune={(isRegroupeeButUneParcelle ? firstParcelleProperties : properties)["commune"]}
                surface={(isRegroupeeButUneParcelle ? firstParcelleProperties : properties)["surface"]}
                ref={(el) => (itemRefs.current[index] = el)}
              />
            }
          }
        )}
      </Paper>

      <Button
        disabled={features.indexOf(selectedFeature) === features.length - 1}
        variant="contained-clear"
        onClick={() => {
          const featureIndex = features.indexOf(selectedFeature);
          setSelectedFeature(features[featureIndex + 1]);
        }}
        sx={{
          display: (isOverflowing && features.length > 1) ? "block" : "none",
          padding: "10px",
          borderRadius: "20px",
          [theme.breakpoints.down("sm")]: {
            position: "absolute",
            right: "5px",
            bottom: bottomHeight + 90 + "px",
            height: "64px",
            borderRadius: "100px",
            opacity: "70%",
            backdropFilter: "blur(50px) " + (isLightTheme ? "brightness(200%)" : "brightness(100%)"),
          }
        }}
      >
        <FontAwesomeIcon icon={faChevronRight}/>
      </Button>
    </Box>
  </>
    ;
};

export default SearchResultInfo;
