import React, {useContext} from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {useTheme} from "@mui/material/styles";
import ButtonGroup from "@mui/material/ButtonGroup";
import {SearchContext, TYPE_RECHERCHE_ADRESSE, TYPE_RECHERCHE_EXPLORATION_COMMUNE, TYPE_RECHERCHE_REFERENCE_CADASTRALE, TYPE_RECHERCHE_SURFACE} from "./context/SearchProvider";
import {AppContext} from "./context/AppProvider";
import SearchPanel from "./common/SearchPanel";
import CircularProgressWithLabel from "./common/CircularProgressWithLabel";
import CommuneSelect from "./common/CommuneSelect";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons/faMagnifyingGlass";
import {faCompass} from "@fortawesome/free-solid-svg-icons/faCompass";

const SearchPopup = () => {
  const theme = useTheme();

  const {
    commune,
    setCommune,
    typeRecherche,
    setTypeRecherche,
    doSearch,
    doExplore,
    formIsValidOrGetErrors
  } = useContext(SearchContext);

  const {
    isMobile,
    isLightTheme,
    loading,
    setMentionsLegalesOpen,
    needToWaitUntil,
    progressToWait,
    bottomHeight
  } = useContext(AppContext)

  return <>
    <Paper
      elevation={0}
      sx={{
        backgroundColor: theme.palette.background.paper,
        display: "flex",
        width: "650px",
        height: (typeRecherche === TYPE_RECHERCHE_EXPLORATION_COMMUNE && !loading) ? "350px" : "450px",
        transition: "height 0.3s ease-out",
        borderRadius: "30px",
        alignItems: "center",
        position: "absolute",
        top: "calc(50% - 22.5px)",
        left: "50%",
        padding: "env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)",
        transform: "translate(-50%, -50%)",
        overflow: "hidden",
        [theme.breakpoints.down("sm")]: {
          width: "100%",
          height: "calc(100% - " + bottomHeight + "px + 10px)",
          top: "calc(50% - 35px)",
          padding: "20px 0 55px 0",
          borderRadius: "0px",
          flexDirection: "column",
          overflowY: "auto",
        },
      }}
    >
      {!loading && <>
        <Box sx={{
          position: "relative",
          width: "35%",
          height: "100%",
          marginLeft: "20px",
          [theme.breakpoints.down("sm")]: {
            height: "30%",
            marginLeft: "0px",
          },
        }}
        >
          <Box
            className="mask-pin"
            sx={{
              position: "absolute",
              width: "100%",
              height: "150px",
              top: "25%",
              border: "none !important",
              backgroundColor: theme.palette.primary.light,
              zIndex: 2,
              maskSize: "80%",
              maskPosition: "70% 50%",
              maskRepeat: "no-repeat",
              [theme.breakpoints.down("sm")]: {
                height: "120px",
                width: "100%",
              }
            }}
          />
          <Box
            className="mask-town"
            sx={{
              position: "absolute",
              width: "100%",
              height: "150px",
              top: "40%",
              border: "none !important",
              transform: "rotate3d(200, 0, 0, 60deg) scale(150%)",
              transformOrigin: "center",
              background: theme.palette.mode === "light" ? "radial-gradient(circle, rgb(105,118,124,20%) 0%, rgb(105,118,124,0%) 50%)" : "radial-gradient(circle, rgb(105,118,124,40%) 0%, rgb(105,118,124,0%) 50%)",
              maskSize: "100%",
              maskPosition: "50% 50%",
              maskRepeat: "no-repeat",
              [theme.breakpoints.down("sm")]: {
                height: "120px",
                width: "100%",
              }
            }}
          />
        </Box>
        <Box
          sx={{
            display: "grid",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            width: "100%",
            height: "100%",
            padding: "20px 0",
            [theme.breakpoints.down("sm")]: {
              justifyContent: "stretch",
            }
          }}
        >
          {typeRecherche !== TYPE_RECHERCHE_EXPLORATION_COMMUNE && <>
            <Typography sx={{
              flex: 1,
              alignSelf: "stretch",
              padding: "20px 40px 0px 40px",
              [theme.breakpoints.down("sm")]: {
                padding: "10px 20px 0px 20px",
                fontSize: "240%"
              }
            }} variant="h1">
              Rechercher une parcelle
            </Typography>

            <ButtonGroup
              disableElevation
              sx={{
                flex: 1,
                alignSelf: "stretch",
                justifySelf: "center",
                padding: "10px 40px 10px 40px",
                [theme.breakpoints.down("sm")]: {
                  padding: "10px 20px 0px 20px",
                }
              }}
              size="small"
              variant="text"
              aria-label="Type de recherche"
            >
              <Button
                sx={{height: "18px"}}
                onClick={() => setTypeRecherche(TYPE_RECHERCHE_SURFACE)}
                color={typeRecherche === TYPE_RECHERCHE_SURFACE ? "primary" : "secondary"}
              >
                {isMobile ? "Surface" : "Par surface"}
              </Button>
              <Button
                sx={{height: "18px"}}
                onClick={() => setTypeRecherche(TYPE_RECHERCHE_ADRESSE)}
                color={typeRecherche === TYPE_RECHERCHE_ADRESSE ? "primary" : "secondary"}
              >
                {isMobile ? "Adresse" : "Par adresse"}
              </Button>
              <Button
                sx={{height: "18px"}}
                onClick={() => setTypeRecherche(TYPE_RECHERCHE_REFERENCE_CADASTRALE)}
                color={typeRecherche === TYPE_RECHERCHE_REFERENCE_CADASTRALE ? "primary" : "secondary"}
              >
                {isMobile ? "Référence cadastrale" : "Par référence cadastrale"}
              </Button>
            </ButtonGroup>

            <SearchPanel
              hideTenterRegrouperParcelles={false}
            />
          </>}

          {typeRecherche === TYPE_RECHERCHE_EXPLORATION_COMMUNE && <>
            <Typography sx={{
              flex: 1,
              alignSelf: "stretch",
              padding: "20px 40px 0px 40px",
              [theme.breakpoints.down("sm")]: {
                padding: "50px 20px 0px 20px",
                fontSize: "240%"
              }
            }} variant="h1">
              Explorer le cadastre
            </Typography>

            <Box
              sx={{
                height: typeRecherche === TYPE_RECHERCHE_EXPLORATION_COMMUNE ? "180px" : "220px",
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
                justifyContent: "center",
                gap: "20px",
                padding: "20px 40px",
                [theme.breakpoints.down("sm")]: {
                  padding: "30px 20px",
                  height: "100%",
                }
              }}>
              <CommuneSelect
                fieldProps={{
                  autoFocus: true,
                  sx: {
                    width: "100%"
                  }
                }}
                initialValue={commune}
                onSelectCommune={(commune) => setCommune(commune)}
                hideDistance={true}
              />
            </Box>
          </>}


          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              alignSelf: "stretch",
              justifyContent: "flex-end",
              padding: "0px 40px 0px 40px",
              [theme.breakpoints.down("sm")]: {
                padding: "10px 20px",
              }
            }}>
            {typeRecherche !== TYPE_RECHERCHE_EXPLORATION_COMMUNE && <Button disabled={formIsValidOrGetErrors() !== true} onClick={doSearch} variant="contained" disableElevation color="secondary">
              <FontAwesomeIcon style={{paddingRight: "5px"}} icon={faMagnifyingGlass}/>Rechercher
            </Button>}
            {typeRecherche === TYPE_RECHERCHE_EXPLORATION_COMMUNE && <Button disabled={formIsValidOrGetErrors() !== true} onClick={doExplore} variant="contained" disableElevation color="secondary">
              <FontAwesomeIcon style={{paddingRight: "5px"}} icon={faCompass}/>Explorer
            </Button>}
          </Box>
        </Box>
      </>}
      {loading && <>
        <Box sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          alignItems: "center",
          padding: "20px"
        }}>
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px 0 10px 0"
          }}>
            <CircularProgressWithLabel
              sx={{margin: "0 10px"}}
              text={needToWaitUntil}
              variant={progressToWait === null ? "indeterminate" : "determinate"}
              value={progressToWait}
            />
            Merci de patienter quelques instants...
          </Box>

          <Box sx={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            flex: 0.8,
            padding: "20px",
            backgroundColor: isLightTheme ? "#CCCCCC" : "#333333",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: isLightTheme ? "#BBBBBB" : "#444444",
            textAlign: "center",
            position: "relative",
            "&::after": {
              content: "\"Publicité\"",
              position: "absolute",
              color: isLightTheme ? "#666666" : "#999999",
              fontStyle: "italic",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              top: "0px",
              left: "0px",
              width: "100%",
              height: "100%",
            }
          }}>
          </Box>
        </Box>
      </>}
    </Paper>
    {!loading && <Button
      variant="contained-clear"
      disableRipple disableFocusRipple
      onClick={(event) => {
        if (typeRecherche === TYPE_RECHERCHE_EXPLORATION_COMMUNE) {
          setTypeRecherche(TYPE_RECHERCHE_SURFACE);
        } else {
          setTypeRecherche(TYPE_RECHERCHE_EXPLORATION_COMMUNE);
        }
      }}
      sx={{
        position: "absolute",
        top: "calc(50% - 22.5px + 225px + 40px)",
        left: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "20px",
        [theme.breakpoints.down("sm")]: {
          position: "absolute",
          top: "unset",
          minWidth: "250px",
          bottom: bottomHeight + "px",
          borderRadius: "100px",
          opacity: "70%",
        }
      }}
    >
      {typeRecherche === TYPE_RECHERCHE_EXPLORATION_COMMUNE ? "Ou rechercher dans le cadastre" : "Ou explorer le cadastre"}
    </Button>}
  </>;
}

export default SearchPopup;
