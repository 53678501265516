import React, {createContext, useState} from "react";
import axios from "axios";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";

export const PAGE_ACCUEIL = "ACCUEIL";
export const PAGE_MENTIONS_LEGALES_CGU = "MENTIONS_LEGALES_CGU";
export const PAGE_EXPLORATION_COMMUNE = "EXPLORATION_COMMUNE";
export const PAGE_FAQ = "FAQ";

export const AppContext = createContext();

const AppProvider = ({children}) => {
  const [cadastreVersionDate] = useState(data.cadastreversiondate);
  const [page, setPage] = useState(options?.page);

  const [loading, setLoading] = useState(page === PAGE_EXPLORATION_COMMUNE);
  const [updatingPopupOpen, setUpdatingPopupOpen] = useState(data.status === "UPDATING");
  const [searchPopupOpen, setSearchPopupOpen] = useState(data.status === "OK");
  const [searchResultInfoOpen, setSearchResultInfoOpen] = useState(false);
  const [exploreResultInfoOpen, setExploreResultInfoOpen] = useState(false);
  const [mentionsLegalesOpen, setMentionsLegalesOpen] = useState(page === PAGE_MENTIONS_LEGALES_CGU);
  const [FAQOpen, setFAQOpen] = useState(page === PAGE_FAQ);
  const [needToWaitUntil, setNeedToWaitUntil] = useState();
  const [progressToWait, setProgressToWait] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const bottomHeight = isMobile ? 70 : 45

  const [showCommuneWidgets, setShowCommuneWidgets] = useState(!isMobile);

  const isLightTheme = theme.palette.mode === "light";

  const openUpdatingPopup = () => {
    setUpdatingPopupOpen(true);
    setSearchPopupOpen(false);
    setSearchResultInfoOpen(false);
    setExploreResultInfoOpen(false);
  };

  const openSearchPopup = () => {
    setUpdatingPopupOpen(false);
    setSearchPopupOpen(true);
    setSearchResultInfoOpen(false);
    setExploreResultInfoOpen(false);
  };

  const openResultPanel = () => {
    setUpdatingPopupOpen(false);
    setSearchPopupOpen(false);
    setSearchResultInfoOpen(page !== PAGE_EXPLORATION_COMMUNE);
    setExploreResultInfoOpen(page === PAGE_EXPLORATION_COMMUNE);
  };

  const getWaitTokenAndWait = async () => {
    try {
      const responseData = await axios.get("/get_wait_token");
      const tokenData = responseData.data.token;
      const token = JSON.parse(tokenData) || [];

      const startTime = new Date();

      const timeToWait = debug ? 0 : (token.time_to_wait * 1000);

      if (timeToWait <= 0) {
        return Promise.resolve(token.id);
      }

      const intervalId = setInterval(() => {
        const currentTime = new Date().getTime();
        const elapsedTime = currentTime - startTime;
        const percentage = Math.min((elapsedTime / timeToWait) * 100, 100);

        setProgressToWait(percentage);
        setNeedToWaitUntil(Math.round((timeToWait - elapsedTime) / 1000));

        if (percentage >= 100) {
          setNeedToWaitUntil(null);
          setProgressToWait(null);
          clearInterval(intervalId);
        }
      }, 100);

      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(token.id);
        }, timeToWait);
      });
    } catch(error) {
      console.error("Error fetching data", error);
    }
  };

  return <AppContext.Provider value={{
    isMobile,
    isLightTheme,
    page,
    setPage,
    cadastreVersionDate,
    loading,
    setLoading,
    updatingPopupOpen,
    openUpdatingPopup,
    searchPopupOpen,
    openSearchPopup,
    searchResultInfoOpen,
    exploreResultInfoOpen,
    openResultPanel,
    mentionsLegalesOpen,
    setMentionsLegalesOpen,
    FAQOpen,
    setFAQOpen,
    needToWaitUntil,
    setNeedToWaitUntil,
    getWaitTokenAndWait,
    progressToWait,
    setProgressToWait,
    alertMessage,
    setAlertMessage,
    bottomHeight,
    showCommuneWidgets,
    setShowCommuneWidgets
  }}>
    {children}
  </AppContext.Provider>
};

export default AppProvider;
